// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@tailwind base;
@tailwind components;
@tailwind utilities;
// Custom Theming for Angular Material
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$conaset-nexteo-fe-primary: mat.define-palette(mat.$indigo-palette);
$conaset-nexteo-fe-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$conaset-nexteo-fe-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$conaset-nexteo-fe-theme: mat.define-light-theme((
  color: (
    primary: $conaset-nexteo-fe-primary,
    accent: $conaset-nexteo-fe-accent,
    warn: $conaset-nexteo-fe-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($conaset-nexteo-fe-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.p-tooltip .p-tooltip-text {
  font-size: 12px;
  text-align: center;
}

.p-datatable-thead {
  border: 1px solid #dee2e6;
  border-radius: 6px !important;
}

.p-datatable-loading-overlay.p-component-overlay {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.p-datatable-loading-icon {
  font-size: 3em !important;
  color: #2563eb
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  .loading-icon {
    font-size: 3em;
    color: #2563eb;
  }
  
  .loading-text {
    color: #2563eb;
    margin-top: 10px;
  }
}

